import { FC, useMemo, useState } from 'react';
import { ClientTagResponse } from '../../models/ClientTag';
import Tooltip from '../shared/Tooltip';
import LanguageUtils from '../../utils/LanguageUtils';
import { SelectListMenu } from '../shared/SelectListMenu';
import { Option } from '../Option';
import { useTranslation } from 'react-i18next';
import TagIcon from '../shared/icon/TagIcon';

type Props = {
  tags: ClientTagResponse[];
  single?: boolean;
  icon?: boolean;
};

const TagGroup: FC<Props> = (props) => {
  const { tags, single = true, icon } = props;

  const [moreTagsOpen, setMoreTagsOpen] = useState(false);
  const {
    i18n: { language },
  } = useTranslation();

  const renderedTags = useMemo(() => {
    return single ? tags.slice(0, 1) : tags;
  }, [single, tags]);

  const moreTags = useMemo(() => {
    return single ? tags.slice(1) : [];
  }, [single, tags]);

  return (
    <div className="flex max-w-full items-center gap-2">
      {icon && <TagIcon className="h-6 w-6" />}

      {renderedTags.map((tag) => (
        <Tooltip key={tag.id} text={LanguageUtils.getTranslation('name', tag.translations, language)} truncatedTextMode>
          {(tooltip) => (
            <span {...tooltip} className="border-gray-3 truncate rounded-lg border bg-white px-2 py-1">
              {LanguageUtils.getTranslation('name', tag.translations, language)}
            </span>
          )}
        </Tooltip>
      ))}

      {moreTags.length > 0 && (
        <SelectListMenu
          isOpen={moreTagsOpen}
          options={moreTags.map((tag, i) => {
            return {
              id: tag.id,
              value: i,
              text: LanguageUtils.getTranslation('name', tag.translations, language),
            };
          })}
          customListItemRenderer={MoreTagsListRenderer}
          onBlur={() => setMoreTagsOpen(false)}
          className="border-none"
          width="w-fit"
        >
          {(triggerProps) => (
            <div
              {...triggerProps}
              className="border-gray-3 bg-gray-5 flex h-8 w-8 flex-shrink-0 cursor-pointer items-center justify-center rounded-full border"
              onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
                setMoreTagsOpen(!moreTagsOpen);
              }}
            >
              +{moreTags.length}
            </div>
          )}
        </SelectListMenu>
      )}
    </div>
  );
};

export default TagGroup;

function MoreTagsListRenderer(props: Option<string, number>) {
  return (
    <div key={props.id} className={`border-gray-6 flex ${props.value > 0 ? 'border-t pt-4' : 'pt-2'}`}>
      {props.text}
    </div>
  );
}
